import styles from "../globalStyles.module.css";
import Dropdown from "react-bootstrap/Dropdown";
import { FaBars } from "react-icons/fa"; // Imports hamburger icon
import { useAppStore } from "../store/appStore";
import { Routes } from "../utils/routes";

function RouterDropdown() {
  const goToRoute = useAppStore(({ setRoute }) => setRoute);

  return (
    <div className={styles["dropdown-menu-container"]}>
      <Dropdown>
        <Dropdown.Toggle
          id="dropdown-basic"
          variant="outline-light"
          size="m"
          className={styles["custom-dropdown-toggle"]}
        >
          <FaBars className={styles["custom-hamburger-icon"]} />
        </Dropdown.Toggle>

        <Dropdown.Menu
          variant="dark"
          className={styles["custom-dropdown-menu"]}
          id="dropdown-menu"
        >
          {Object.keys(Routes).map((route) => (
            <Dropdown.Item
              className={styles["dropdown-item"]}
              onClick={() => {
                goToRoute(route);
              }}
              key={route}
            >
              {route}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default RouterDropdown;
