import { useEffect, useState } from "react";
import styles from "../../globalStyles.module.css";
import { getImgUrl } from "../../utils/helpers";

function EnlargedImg({ img, gallery, setImg, closeModal }) {
  const imgIndex = gallery.indexOf(img)
  const [index, setIndex] = useState(imgIndex)

  const { id, vertical } = img

  const moreThanOne = gallery?.length > 1
  const enlargedClass = vertical ? 'enlarged-img-vertical' : 'enlarged-img'

  const setPrevious = () => {
    setImg(gallery[index ? index - 1 : gallery.length - 1])
  }

  const setNext = () => {
    setImg(gallery[index === gallery.length - 1 ? 0 : index + 1])
  }

  const handleKeyPress = e => {
    const { key } = e

    const keyEvents = {
      ArrowLeft: setPrevious,
      ArrowRight: setNext
    }

    const fn = keyEvents[key]

    if (fn) fn()
  }

  useEffect(() => {
    setIndex(imgIndex)
  }, [img])

  window.addEventListener('keydown', handleKeyPress)
  
  return (
    <div className={styles['enlarged-img-box']}>
      <button 
        className={styles["work-close-btn"]}
        onClick={closeModal}
      >
        x    
      </button>
      {moreThanOne && 
        <button
          className={styles["enlarged-img-btn"]}
          onClick={setPrevious}
        >{'<'}</button>}

      <img 
        className={styles[enlargedClass]}
        src={getImgUrl(id)}
        alt={id}
        onClick={setNext}
      />
      
      {moreThanOne && 
        <button
          className={styles["enlarged-img-btn"]}
          onClick={setNext}
        >{'>'}</button>}
    </div>
  ) 
}

export default EnlargedImg