import styles from "./globalStyles.module.css"
import Header from "./components/Header";
import MainSection from "./components/MainSection";
import Footer from "./components/Footer";
import { useAppStore } from "./store/appStore";
import WorkGallery from "./components/gallery/WorkGallery";

// TODO: global, on every page load scroll up to the top
// TODO: works gallery, scroll when selecting items outside border (mobile)

function MainPage() {
  const work = useAppStore(({ selectedWork }) => selectedWork)

  return (
    <>
      <div className={styles["main-page"]}>
        <Header />
        <MainSection />
        <Footer />
      </div>
      {work && <WorkGallery {...work} />}    
    </>
  );
}

export default MainPage;
