import styles from "../../globalStyles.module.css"
import { getImgUrl } from "../../utils/helpers"

function ThumbnailImg({ img, selected }) {
  const { id, vertical } = img
  const thumbnailClass = vertical ? 'thumbnail-img-vertical' : 'thumbnail-img'

  return (
    <img 
      className={styles[thumbnailClass]}
      style={selected ? {outline: '4px white solid'} : {}}
      src={getImgUrl(id)}
      alt={id}  
    />
  )
}

export default ThumbnailImg