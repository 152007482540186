import React from "react";
import styles from "../globalStyles.module.css";

function Bio() {
  return (
    <div className={styles["container-bio"]}>
      <div className={styles["bio-photo-container"]}>
        <img className={styles["bio-photo"]} src="Parol.A_portret.jpg" />
      </div>
      <div className={styles["bio-text"]}>
        <h2>education</h2>
        <ul>
          <li>2002 – 2005: University of Warsaw – Musicology, Warsaw, PL</li>
          <li>
            2015 – 2017: The Folk University of Artistic Crafts in Wola Sękowa -
            Weaving / Embroidery Diploma, Wola Sękowa, PL
          </li>
        </ul>

        <p>
          Since 2022 member of The Association of Polish Artists and Designers
          ZPAP / Fiber Art / Warsaw, PL
        </p>

        <h2>experience</h2>
        <ul>
          <li>
            2012 - 2018: Polish Textile Conservation and Renovation Studio,
            Warsaw, PL – historic textile restoration
          </li>
          <li>
            2018 – present: Conservation and Renovation Company RekonArt,
            Warsaw, PL – historic textile restoration
          </li>
        </ul>

        <p>
          Polish textile and visual artist, embroiderer/weaver, who lives and
          works in Warsaw, Poland. She was born in 1983. For several years, she
          traveled and lived in different countries in Europe. In 2008, she
          began to study and explore the history of embroidering. She practiced
          old techniques that were used in embroidering in the past and gained
          much more experience while working in the historic conservation and
          renovation studio in Warsaw. Her artwork combines old, traditional
          techniques, high craft in embroidering and weaving with a modern,
          experimental approach. Her goal is to achieve a synthesis of ancient
          methods, attention to detail, craftsmanship, and contemporary form of
          expression. Her work has been selected for various national and
          international textile art biennales. Creating fiber art is the essence
          of her life.
        </p>
      </div>
    </div>
  );
}

export default Bio;
