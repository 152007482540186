import styles from "../../../src/globalStyles.module.css";

const EmailForm = () => {
  return (
    <div className={styles["find-me-container"]}>
      <p>
        or find me on{" "}
        <a
          href="https://www.facebook.com/profile.php?id=100006573829299"
          target="_blank"
          rel="noopener noreferrer"
        >
          Facebook
        </a>{" "}
        or{" "}
        <a
          href="https://www.instagram.com/holaparol/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Instagram.
        </a>
      </p>
    </div>
  );
};

export default EmailForm;
