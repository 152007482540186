import { useState } from "react";
import styles from "../../../src/globalStyles.module.css";

function ContactForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [formInactive, setFormInactive] = useState(false);
  const [notification, setNotification] = useState({
    content: "",
    color: null,
  });

  const formFilled = name && email && subject && content;

  const clearInputs = () => {
    setName('')
    setEmail('')
    setSubject('')
    setContent('')
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    setFormInactive(true);

    setNotification({
      content: "Sending your message, please wait.",
      color: "#b4ede9",
    });

    const message = { name, email, subject, content };
    const url = `${process.env.REACT_APP_ITEMS_DB_URL}/send?token=${process.env.REACT_APP_API_TOKEN}`;
    const res = await fetch(url, {
      method: "POST",
      body: JSON.stringify({ message }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    const { status } = res;

    // TODO: not very nice:) if we have time, correct
    // TODO: common req service
    if (status == 401)
      setNotification({
        content: "Invalid authentication.",
        color: "#c22715",
      });
    else if ([535, 503, 554].includes(status))
      setNotification({
        content:
          "Server error, please keep in touch via Facebook or Instagram, or try again later. Sorry!",
        color: "#c22715",
      });
    else if (status == 200) {
      clearInputs()
      setNotification({
        content: "Message sent correctly. Thank you!",
        color: "#519c1c",
      });
    }

    setTimeout(
      () =>
        setNotification({
          content: "",
          color: null,
        }),
      3000
    );

    setFormInactive(false);
  };

  return (
    <div className={styles["contact-form-container"]}>
      <form onSubmit={handleSubmit}>
        <div className={styles["contact-form-sender-info"]}>
          <div>
            <label htmlFor="name">
              name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
              disabled={formInactive}
            />
          </div>
          <div>
            <label htmlFor="email">
              e-mail
            </label>
            <input
              type="email"
              id="email"
              name="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={formInactive}
            />
          </div>
        </div>
        <label htmlFor="subject" className={styles["contact-subject"]}>
          subject
        </label>
        <input
          type="text"
          id="subject"
          name="subject"
          required
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          disabled={formInactive}
        />
        
        <label htmlFor="content">
          content
        </label>
        <textarea
          id="content"
          name="content"
          rows="4"
          cols="50"
          required
          value={content}
          onChange={(e) => setContent(e.target.value)}
          disabled={formInactive}
        ></textarea>
        <br></br>
        <input
          className={styles["contact-form-send-btn"]}
          type="submit"
          value="send email"
          disabled={formInactive || !formFilled}
        />

        {notification.content && (
          <div
            className={styles["msg-notification"]}
            style={{ color: notification.color, fontWeight: 700 }}
          >
            {notification.content}
          </div>
        )}
      </form>
    </div>
  );
}

export default ContactForm;
