import styles from "../globalStyles.module.css";
import RouterDropdown from "./RouterDropdown";

function Header() {
  return (
    <div className={styles["header"]}>
      <RouterDropdown />
      <div className={styles["heading-container"]}>
        <h1 className={styles["heading"]}>ola parol</h1>
      </div>
    </div>
  );
}

export default Header;
