import { useEffect, useState } from "react";
import styles from "../../globalStyles.module.css";
import { useAppStore } from "../../store/appStore";
import EnlargedImg from "./EnlargedImg";
import ThumbnailGallery from "./ThumbnailGallery";

function WorkGallery(work) {
  const { gallery, title } = work

  const [img, setImg] = useState(null)

  const setWork = useAppStore(({ setSelectedWork }) => setSelectedWork)

  const closeModal = () => setWork(null)

  const handleKeyPress = e => {
    if (e.key === 'Escape') closeModal() 
  }
  
  useEffect(() => {
    setImg(gallery[0])
  }, [])

  window.addEventListener('keydown', handleKeyPress)

  return (img &&
    <>
      <div
        className={styles["modal"]}
        onClick={closeModal}
      >
      </div>
      <div className={styles["modal-content"]}>
        <div className={styles["work-container"]}>
          {/* delete title? Now it has display: none */}
          <div className={styles["work-title"]}>{title}</div>
          <EnlargedImg 
            img={img} 
            gallery={gallery}
            setImg={setImg}
            closeModal={closeModal}
          />
          <ThumbnailGallery
            enlargedId={img.id}
            setImg={setImg}
            gallery={gallery}
          />
        </div>
      </div>
    </>

  )
}

export default WorkGallery