import ContactForm from "../components/contact/ContactForm";
import FindMe from "../components/contact/FindMe";
import styles from "../globalStyles.module.css";

const Contact = () => {
  return (
    <div className={styles["contact-container"]}>
      <h2>contact me:</h2>
      <p>(please fill in all the fields)</p>
      <ContactForm />
      <FindMe />
    </div>
  );
};

export default Contact;
