import { useEffect, useState } from "react";
import styles from "../globalStyles.module.css";
import Item from "../components/Item";
import { fetchData } from "../controllers/apiController";

function Works() {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchItems = async () => {
      const data = await fetchData('/items')

      setItems(data?.works || []);
    };
    fetchItems();
  }, []);

  return (
    <div className={styles["items-view"]}>
      <div className={styles["items-container"]}>
        {items.map(item => <Item {...item} key={item.id} />)}
      </div>
    </div>
  );
}

export default Works;
