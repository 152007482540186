import styles from "../globalStyles.module.css";
import { useAppStore } from "../store/appStore";
import { Routes } from "../utils/routes";

function RouterMenuBtn({ route, isSelected }) {
  const goToRoute = useAppStore(({ setRoute }) => setRoute)
  const switchRoute = () => { goToRoute(route) }

  return (
    <button 
      className={`${styles["route-menu-btn"]} ${isSelected && styles["route-menu-btn-selected"]}`}
      onClick={switchRoute}  
    >
      {Routes[route]}
    </button> 
  )
}

export default RouterMenuBtn