import { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import styles from "../globalStyles.module.css";
import { getImgUrl } from "../utils/helpers";
import { useAppStore } from "../store/appStore";
import { Routes } from "../utils/routes";
import { fetchData } from "../controllers/apiController";

function Home() {
  const [items, setItems] = useState([]);
  const [item, setItem] = useState(null);

  const onItemSlide = (index) => {
    setItem(items[index]);
  };

  const goToRoute = useAppStore(({ setRoute }) => setRoute);

  const goToGallery = () => {
    goToRoute(Routes.works);
  };

  useEffect(() => {
    const fetchCarousel = async () => {
      const data = await fetchData("/carousel");

      if (!data) return;

      const { carousel } = data;

      if (carousel) {
        setItem(carousel[0]);
        setItems(carousel);
      }
    };
    fetchCarousel();
  }, []);

  return (
    <div className={styles["carousel-container"]}>
      <Carousel
        fade
        controls={false}
        indicators={false}
        interval={2000}
        onSlid={onItemSlide}
      >
        {items.map((item) => (
          <Carousel.Item key={item.id}>
            <Carousel.Caption className={styles["carousel-element"]}>
              <img
                className={styles["carousel-element-img"]}
                src={getImgUrl(item.img)}
                onClick={goToGallery}
              />
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
      <div className={styles["carousel-element-title-box"]}>
        <div className={styles["carousel-element-title"]}>
          {item && item.title}
        </div>
        <div className={styles["carousel-element-title-info"]}>
          (click on the image to view the gallery)
        </div>
      </div>
    </div>
  );
}

export default Home;
