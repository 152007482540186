import { useState } from "react"
import styles from "../globalStyles.module.css"
import { useAppStore } from "../store/appStore"
import { getImgUrl } from "../utils/helpers"

function Item(item) {
  const { title, mini } = item
  const [displayTitle, setDisplayTitle] = useState(false) // TODO: try css hover

  const setWork = useAppStore(({ setSelectedWork }) => setSelectedWork)

  return (
    <div className={styles['item-box-container']}>
      <button 
        className={styles['item-box']}
        onMouseOver={() => setDisplayTitle(true)}
        onMouseOut={() => setDisplayTitle(false)}
        onClick={() => setWork(item)}
      >
        <div 
          className={styles['item-title']}
          style={displayTitle ? {display: 'block' } : {}}  
        >
          {title}
        </div>
        <div
          className={styles['item-img-box']}
          style={displayTitle ? {opacity: '0.4' } : {}}  
        >

          <img 
            className={styles['item-img']}
            src={getImgUrl(mini)}
            alt={title}  
          />
        </div>
      </button>
    </div>
  )
}

export default Item