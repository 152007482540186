import ThumbnailImg from "./ThumbnailImg";
import styles from "../../globalStyles.module.css";

function ThumbnailGallery({ gallery, setImg, enlargedId }) {

  return (
    <div className={styles["thumbnail-gallery"]}>
      {gallery.map(img => (
        <div 
          onClick={() => setImg(img)}
          key={img.id}  
        >
          <ThumbnailImg 
            img={img}
            selected={enlargedId === img.id}
          />
        </div>
      ))}
    </div>
  )
}

export default ThumbnailGallery