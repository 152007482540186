import styles from "../globalStyles.module.css";

function Exhibitions() {
  return (
    <div className={styles["container-exhibitions"]}>
      <h2>selected exhibitions</h2>
      <ul>
        <li>
          2023 - The 7th Riga International Triennial of Textile and Fiber Art,
          Riga, Latvia
        </li>
        <li>
          2023 - "Fiber’s Touch" group exhibition by The Association of Polish
          Artists and Designers, Fiber Art "Lufcik" Gallery, Warsaw, PL
        </li>
        <li>
          2022 - 12 Baltic Mini Textile Gdynia 2022, NATURE International Baltic
          Triennial of Miniature Textile in Gdynia, PL
        </li>
        <li>
          2022 - CONTEXTILE 2022, RE-MAKE, Contemporary Textile Art Biennial,
          Guimarães, Portugal
        </li>
        <li>
          2022 - 11 International Artistic Linen Cloth Biennial "Z Krosna do
          Krosna" 2022, PL
        </li>
        <li>
          2022 - "Fiber’s Touch" - group exhibition by The Association of Polish
          Artists and Designers, 5th Fabric’s Festival 2022, Szczecin, PL
        </li>
        <li>
          2022 - "Fiber Art" – group exhibition by The Association of Polish
          Artists and Designers 2022, Warsaw, Poland
        </li>
        <li>
          2021 - 3rd Textile Art Biennial of Poznan 2021 - FUTURE OF FREEDOM,
          Poznan, PL
        </li>
      </ul>
    </div>
  );
}

export default Exhibitions;
