import Home from "../pages/Home";
import Exhibitions from "../pages/Exhibitions";
import Bio from "../pages/Bio";
import Contact from "../pages/Contact";
import Works from "../pages/Works";
import styles from "../globalStyles.module.css";
import RouteMenu from "./RouteMenu";
import { useAppStore } from "../store/appStore";
import { Routes } from "../utils/routes";

function MainSection() {
  const selectedRoute = useAppStore(({ route }) => route)

  const mainComponent = {
    [Routes.home]: <Home />,
    [Routes.bio]: <Bio />,
    [Routes.works]: <Works />,
    [Routes.exhibitions]: <Exhibitions />,
    [Routes.contact]: <Contact />
  }[selectedRoute]

  return (
    <div className={styles["main-section"]}>
      <div className={styles["main-section-left-menu"]}>
        <RouteMenu selectedRoute={selectedRoute} />
      </div>

      <div className={styles["main-section-right"]}>
        {mainComponent}
      </div>
    </div>
  );
}
export default MainSection;
